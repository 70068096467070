import {
  Box,
  Button,
  CircularProgress,
  Container,
  Grid,
  Icon,
  Link,
  Paper,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, { useContext, useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import C from '../../../../../shared/constants/general';
import { LiveContainer } from '../../../live/LiveContainer';
import FollowToggle from '../../../shared/components/lists/FollowToggle';
import CoChipSmall from '../../../shared/components/ui/CoChipSmall';
import { EntityCards } from '../../../shared/components/ui/EntityCards';
import UserAvatar from '../../../shared/components/ui/UserAvatar';
import { AuthContext } from '../../../shared/context/AuthContext';
import { ConferenceContext } from '../../../shared/context/ConferenceContext';
import { UserContext } from '../../../shared/context/UserContext';
import { infoList } from '../../../shared/utils/DataFormat';
import axios from 'axios';

const useStyles = makeStyles((theme) => ({
  profileBg: {
    backgroundColor: theme.palette.background.default,
    minHeight: '100vh',
  },
  editButton: {
    // color: '#ffffff',
  },
  profileAvatarBg: {
    backgroundColor: theme.palette.background.shadedown_20,
  },
  confBackground: {
    width: 80,
    height: 100,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  confEditor: {
    backgroundColor: '#222222',
  },
  infoTitle: {
    textTransform: 'uppercase',
    opacity: 0.7,
  },
  unselected: {
    opacity: 0.5,
  },
  linkIcon: {
    marginRight: 8,
  },
  noPadding: {
    padding: 0,
  },
  infoDescription: {
    whiteSpace: 'pre-line',
  },
  rqimg: {
    width: '100%',
    maxWidth: '296px',
    height: 'auto',
    borderRadius: 5,
    overflow: 'hidden',
  },
  table: {
    minWidth: 650,
    border: '1px solid #e0e0e0',
    backgroundColor: '#f9f9f9',
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'left',
    background: theme.palette.background.paper,
    borderRadius: 10,
    minHeight: 10,
    maxHeight: 300,
    height: '100%',
    overflow: 'auto',
  },
  question: {
    marginTop: 10,
    width: '100%',
  },
}));

const Profile = withRouter((props) => {
  const classes = useStyles();
  const { userState, dispatch, actions } = useContext(UserContext); // Hämta Context
  const { conferenceState, actions: conferenceActions } = useContext(
    ConferenceContext
  ); // Hämta Context
  const { authState } = useContext(AuthContext); // Hämta Context
  const [pretixData, setPretixData] = useState({});
  const [pretixProductData, setPretixProductData] = useState(null);
  const [pretixProductError, setPretixProductError] = useState(null);
  const [pretixError, setPretixError] = useState('');

  const isMe =
    props.isMe ||
    (authState.userLoggedIn && authState.user.id === props.match.params.id);

  const updateUser = (userData) => {
    dispatch({ type: 'USER_SUCCESS', user: userData });
  };

  const toggleStatus = (status) => {
    actions.updateVisitorStatus(
      conferenceState.conferenceDetail.id,
      props.match.params.id,
      status
    );
  };

  const fetchPretixProductData = () => {
    axios
      .get(`/api/mypretixproductdata`)
      .then((response) => {
        const data = response.data;
        setPretixProductData(data);
      })
      .catch((error) => {
        setPretixProductError(error.response.data.message);
      });
  };

  const fetchPretixData = () => {
    axios
      .get(`/api/mypretixdata`)
      .then((response) => {
        const data = response.data;
        setPretixData(data);
      })
      .catch((error) => {
        setPretixError(error.response.data.message);
      });
  };

  useEffect(() => {
    if (authState.userLoggedIn) {
      actions.loadPublicProfile(
        isMe ? authState.user.id : props.match.params.id
      );
      fetchPretixData();
      fetchPretixProductData();
    }
    conferenceActions.loadPrefetchedDetail();
  }, [authState.userLoggedIn]);

  return userState.userLoaded && userState.user ? (
    <LiveContainer welcomeState={props.welcomeState}>
      <Box className={classes.profileBg}>
        {pretixData && pretixData.qr && (
          <Box textAlign="center">
            Check in:
            <br />
            <img className={classes.rqimg} src={pretixData.qr} />
          </Box>
        )}
        {pretixError && (
          <Box textAlign="center" color="error.main">
            {pretixError}
          </Box>
        )}

        <Box className={classes.profileAvatarBg}>
          {/*<VisitorNav backArrow={'/'} fullscreen={true} {...props} />*/}
          <Container className={classes.noPadding} maxWidth="lg">
            <Box align="center" pb={2} pt={2}>
              <UserAvatar size="large" user={userState.user} />
              <Box py={1}>
                <Typography variant="h5">{userState.user.full_name}</Typography>
                {userState.user.title && (
                  <Typography variant="body1" className={classes.infoTitle}>
                    {userState.user.title}
                  </Typography>
                )}
                <Typography variant="body2">
                  {infoList([
                    userState.user.company,
                    userState.user.location_city,
                  ])}
                </Typography>
                {isMe && (
                  <Button
                    className={classes.editButton}
                    href="/profile/edit"
                    startIcon={<Icon>edit</Icon>}
                  >
                    Edit profile
                  </Button>
                )}
              </Box>
            </Box>
          </Container>
        </Box>
        {pretixProductData && (
          <Typography variant="h6" style={{ marginTop: 20, marginLeft: 20 }}>
            My tickets information for {pretixProductData?.event?.toUpperCase()}
          </Typography>
        )}
        <Box align="center" pb={2} pt={2} ml={4} mr={4} mb={4} mt={2}>
          {pretixProductData && (
            <Grid container spacing={3} justifyContent="center">
              {pretixProductData?.positions?.map((position) => (
                <Grid item xs={12} md={6} lg={4} key={position.id}>
                  <Paper className={classes.paper}>
                    <Typography variant="h6">
                      {position?.item?.name?.[pretixProductData?.locale]} -{' '}
                      {
                        position?.item?.category?.name?.[
                          pretixProductData?.locale
                        ]
                      }
                    </Typography>
                    <Typography variant="body1">
                      {position?.item?.description?.[pretixProductData?.locale]}
                    </Typography>

                    {/* <Typography
                      variant="body1"
                      style={{ fontWeight: 'bold', marginTop: 10 }}
                    >
                      Attendee name:{' '}
                      {position?.attendee_name || 'No attendee found'}
                    </Typography> */}

                    {position?.answers?.length > 0 ? (
                      position?.answers?.map((answer) => (
                        <Box
                          className={classes.question}
                          key={answer.question.id}
                        >
                          <Typography
                            variant="body1"
                            style={{ fontWeight: 'bold' }}
                          >
                            {
                              answer.question.question?.[
                                pretixProductData?.locale
                              ]
                            }
                          </Typography>
                          <Typography variant="body2">
                            {answer.answer}
                          </Typography>
                        </Box>
                      ))
                    ) : (
                      <></>
                    )}
                    {position?.variation ? (
                      <Box className={classes.question}>
                        <Typography
                          variant="body1"
                          style={{ fontWeight: 'bold' }}
                        >
                          {`1 variation found`}
                        </Typography>

                        <Typography variant="body1">
                          {
                            position?.variation?.value?.[
                              pretixProductData?.locale
                            ]
                          }{' '}
                          -{' '}
                          {position?.variation?.description?.[
                            pretixProductData?.locale
                          ] || 'No description'}
                        </Typography>
                      </Box>
                    ) : (
                      <></>
                    )}
                  </Paper>
                </Grid>
              ))}
            </Grid>
          )}
          {!pretixProductData && !pretixProductError && (
            <Box align="center">
              <CircularProgress disableShrink />
            </Box>
          )}
        </Box>
        <Box>
          <Container maxWidth="sm">
            <Paper>
              <Box py={2} align="center">
                {!isMe && (
                  <Box pt={1} pb={1}>
                    <FollowToggle
                      updatedCallback={updateUser}
                      user={userState.user}
                    />
                  </Box>
                )}

                {userState.user.tags
                  .filter((tag) => tag.type === 'skill')
                  .map((tag) => (
                    <CoChipSmall
                      key={tag.id}
                      tagtype={tag.type}
                      label={tag.title}
                    />
                  ))}
                {userState.user.tags
                  .filter((tag) => tag.type === 'scouting')
                  .map((tag) => (
                    <CoChipSmall
                      key={tag.id}
                      tagtype={tag.type}
                      label={tag.title}
                    />
                  ))}

                {userState.user.info_description ? (
                  <Box display="flex" align="left" pt={1} alignItems="center">
                    <Typography
                      variant="body2"
                      className={classes.infoDescription}
                    >
                      {userState.user.info_description}
                    </Typography>
                  </Box>
                ) : (
                  <></>
                )}

                {userState.user.info_website && (
                  <Box display="flex" pt={1} alignItems="center">
                    <Icon className={classes.linkIcon}>home</Icon>{' '}
                    <Link
                      target="_blank"
                      href={`${userState.user.info_website}`}
                    >
                      {userState.user.info_website}
                    </Link>
                  </Box>
                )}

                {!isMe &&
                  authState.user.access_level ===
                    C.VISITOR.ACCESS_LEVEL.MODERATOR && (
                    <Box py={2}>
                      <em>
                        As you are moderator you can bann users, making them
                        unable to perform any actions like chatting etc.
                      </em>
                      <br />
                      <br />
                      {userState.user.status === C.USER.STATUS.BANNED && (
                        <>
                          <Box color="error.main">
                            {userState.user.full_name} is banned
                          </Box>
                          <Button
                            onClick={() => toggleStatus(C.USER.STATUS.VERIFIED)}
                            color="secondary"
                            variant="contained"
                            startIcon={<Icon>block</Icon>}
                          >
                            un:Bann user
                          </Button>
                        </>
                      )}
                      {userState.user.status === C.USER.STATUS.VERIFIED && (
                        <Button
                          onClick={() => toggleStatus(C.USER.STATUS.BANNED)}
                          color="primary"
                          variant="contained"
                          startIcon={<Icon>block</Icon>}
                        >
                          Bann user
                        </Button>
                      )}
                    </Box>
                  )}
              </Box>
            </Paper>
          </Container>
          {userState.user.entities.length > 0 && (
            <Container maxWidth="lg">
              <EntityCards entities={userState.user.entities} history={null} />
            </Container>
          )}
        </Box>
      </Box>
    </LiveContainer>
  ) : (
    <Box align="center">
      <CircularProgress disableShrink />
    </Box>
  );
});

export default Profile;
